import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tab"];
  declare tabTargets;

  setAriaSelected(event) {
    this.tabTargets.forEach((element) =>
      element.setAttribute("aria-selected", "false"),
    );
    event.currentTarget.setAttribute("aria-selected", "true");
  }
}
